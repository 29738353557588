<template>

  <div style="height: 100vh!important;">

    <div style="margin-top: 40vh!important;">
      <div class="text-center w3-center justify-center" style="margin: 0 auto!important; ">

        <v-progress-circular size="40" indeterminate color="#AB005E"></v-progress-circular>

      </div>
    </div>



  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "transactionVerifier",
  computed: {
    ...mapGetters([
      'getCheckOutDetails',
      'getVerifiedTransaction'
    ])
  },
  async mounted() {

    //if redirect url exists, redirect to passed url
    //console.log(this.getCheckOutDetails)
    //console.log(this.getCheckOutDetails.redirecturl)
    //console.log(window.sessionStorage.getItem('onComplete'))
    if (!!this.getCheckOutDetails.redirecturl) return window.parent.location.href = this.getCheckOutDetails?.redirecturl

    //else if broadcast an event to user complete function
    if (window.sessionStorage.getItem('onComplete') === 'true') {

      await this.$store.dispatch('verifyTransaction', {
        reference: this.$route.query.tx_ref || this.$route.query.trxref || sessionStorage.reference,
        confirm: this.$route.query.confirm,
        gateway: sessionStorage.getItem('gateway'),
        gatewayref: this.$route.query.transaction_id || this.$route.query.trxref || this.$route.query.reference || sessionStorage.reference,
        gatewaystatus: this.$route.query.status,
      })
      return window.parent.postMessage(this.getVerifiedTransaction, this.$route.query?.href || '*')

    }


    //else alert completed transaction
    window.alert("Transaction successfully completed")
    return window.parent.location.href = window.sessionStorage.getItem('location')


  }
}
</script>

<style scoped>

</style>
